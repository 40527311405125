<template>
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">投资控制文件</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan" v-if="showAdd">
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="addOrUpdateHandle()">新增</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row
					style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<el-form-item label="文件名" class="selFormItem">
							<el-input class="selSearchInput" v-model="fileName" type="input" rows="1"
								placeholder="请填写"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="getDataList()"
							v-if="showSearch">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 550px;" body-style="height:510px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="490" :row-class-name="tableRowClassName" ref="dataListTable"
								class="dataListTable" :data="dataList" header-align="center" border
								style="width: 100%; margin-bottom: 20px;" header-cell-class-name="dataListTHeader"
								:cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="fileName" label="文件名称" align="center">
								</el-table-column>
								<el-table-column prop="beginTime" label="开始时间" align="center">
								</el-table-column>
								<el-table-column prop="endTime" label="结束时间" align="center">
								</el-table-column>
								<el-table-column label="操作" v-if="showDelete || showEdit || showDownload" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="selectFile(scope.row.filePathUrl)"
											v-if="showDownload && scope.row.filePathUrl">下载</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.recNo)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recNo)" v-if="showDelete">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>
<script>
import AddOrUpdate from './controlFiles-add-or-update'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			showDownload: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			addOrUpdateVisible: false,
			timeArray: [],
			fileName: "",
			form: {},
			dataList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		};
	},
	components: {
		AddOrUpdate
	},
	mounted() {
		this.pageIndex = 1;
		this.showSearch = $common.isAuth('controlFiles:search');
		this.showAdd = $common.isAuth('controlFiles:add');
		this.showDownload = $common.isAuth('controlFiles:download');
		this.showEdit = $common.isAuth('controlFiles:edit');
		this.showDelete = $common.isAuth('controlFiles:delete');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
	},
	methods: {
		resetFormData() {
			this.fileName = '';
			this.pageIndex = 1;
			this.getDataList();
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		handleSelectionChange(selection, item) {
			this.tableSelVal = selection;
			let _tableSelIdArray = [];
			selection.forEach((item) => {
				_tableSelIdArray.push(item.probId)
			})
			this.tableSelIdArray = _tableSelIdArray;
			if (selection.length == this.dataList.length) {
				this.selAllFlg = true;
			} else {
				this.selAllFlg = false;
			}
		},
		// 删除
		deleteHandle(id) {
			var ids = id ? [id] : this.dataListSelections.map(item => {
				return item.recNo
			})
			this.$confirm(`您确定要对选择记录进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/progresscontrolfile/delete/" + ids,
					method: 'post',
					data: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/progresscontrolfile/list",
					method: "get",
					params: {
						limit: this.pageSize,
						page: this.pageIndex,
						beginTime: this.timeArray[0] ? this.timeArray[0] : "",
						endTime: this.timeArray[1] ? this.timeArray[1] : "",
						fileName: this.fileName,
						subjectNo: $common.getItem("subjectNo"),
						workNo: $common.getItem("workNo")
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		selectFile(url) {
			window.open(url);
		},
		// 新增检验项目
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}


::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>

