<template>
	<el-dialog :title="!dataForm.recNo ? '投资控制文件新增' : '投资控制文件修改'" :close-on-click-modal="false" :visible.sync="visible"
		width="700px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="文件名称" prop="fileName" class="item">
						<el-input v-model="dataForm.fileName" placeholder="请选择" style="width: 470px;"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="时间" prop="timeList" class="item">
						<el-date-picker class="selItemInput" v-model="dataForm.timeList" type="daterange"
							value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="扫描文件" class="item">
						<div class="addFileBox">
							<div v-if="dataForm.filePathUrl">
								<!-- <span style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{dataForm.loadFileName}}
								</span> -->
								<el-button v-preventReClick type="success" size="small" @click="selectFile()">查看</el-button>
								<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
							</div>
						</div>
						<el-upload v-if="!dataForm.filePathUrl" class="addFileBox" ref="uploadScanFile" :action="action"
							:http-request="uploadScanFile">
							<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add"
								style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>

			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "problem-add-or-update",
	data() {
		return {
			addImg: addImg,
			id: "",
			visible: true,
			form: {},
			action: "",
			fileType: "",
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				timeList: [],
				beginTime: "",
				endTime: "",
				fileName: "",
				loadFileName: "",
				filePath: "",
				filePathUrl: '',
				recNo: "",
				remark: ""
			},
			dataRule: {
				fileName: [
					{ required: true, message: "文件名称不能为空！", trigger: "blur" }
				],
			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				timeList: [],
				beginTime: "",
				endTime: "",
				fileName: "",
				filePath: "",
				loadFileName: "",
				filePathUrl: '',
				recNo: "",
				remark: ""
			}
			this.dataForm.recNo = id ? id : 0;
			this.getFinishedFile();
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})

		},
		selectFile() {
			window.open(this.dataForm.filePathUrl);
		},
		getFinishedFile() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/progresscontrolfile/getControlFileKind/" + $common.getItem("workNo") + "/" + $common.getItem("subjectNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.fileType = data.body;
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/progresscontrolfile/info/" + this.dataForm.recNo,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					this.dataForm.timeList = [this.dataForm.beginTime, this.dataForm.endTime]
				}
			});
		},
		delFile() {
			this.dataForm.filePath = "";
			this.dataForm.loadFileName = "";
			this.dataForm.filePathUrl = "";
		},
		uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.fileType,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.filePath = res.body.fileSavePath;
					this.dataForm.loadFileName = res.body.fileName;
					this.dataForm.filePathUrl = res.body.fileUri;
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/progresscontrolfile/save";
					if (this.dataForm.recNo && this.dataForm.recNo != 0) {
						_url = "/business/progresscontrolfile/update";
					}
					this.dataForm.beginTime = this.dataForm.timeList[0] ? this.dataForm.timeList[0] : "";
					this.dataForm.endTime = this.dataForm.timeList[1] ? this.dataForm.timeList[1] : "";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 1030px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	position: relative;
}

.item::v-deep .el-form-item__label {
  color: #00428E !important;
  margin-right: 10px !important;
  font-weight: bold !important;
  background-color: #E1F1FF;
  white-space: nowrap !important;
  border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
  border-bottom: solid #dadada 1px;
  border-left: solid #dadada 1px;
  border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
  border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
  display: flex !important;
  align-items: stretch !important;
  margin-bottom: 0px !important;
}

.selInput {
  width: 405px !important;
}
</style>
